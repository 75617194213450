<template>
  <loader v-if="isLoading"/>
  <div
    v-else
    class="container"
  >
    <user-nav/>

    <h1 class="page-title">МОИ ЗАКАЗЫ</h1>
    <div class="cards">
      <div
        v-for="(order, index) in getOrders"
        :key="index"
        class="cards-item"
      >
        <div class="cards-item__row">
          <div class="cards-item__row__status blue"></div>
          <div class="cards-item__row__title">
            <div
              v-for="(item, j) in order.items"
              :key="item.id"
              class="cards-item__row__title-item"
            >
              {{ j + 1 }}. {{ item.title }}<br>
            </div>
          </div>
        </div>
        <div class="cards-item__row">
          <span class="cards-item__row__cost">Итого:</span>
          <span class="cards-item__row__cost">{{ order.cost | formatPrice }}</span>
        </div>
        <div class="cards-item__row">
          <span class="cards-item__row__date">Дата заказа:</span>
          <span class="cards-item__row__date">{{ order.order_date | formatDate }}</span>
        </div>
        <div class="cards-item__row">
          <span class="cards-item__row__time">Статус:</span>
          <span class="cards-item__row__time">
            <span>{{ order.status }}</span>
          </span>
        </div>
        <div class="cards-item__btns-wrap">
          <button
            @click.prevent="removeOrder(order.id)"
            class="btn middle grey cards-item__btn"
          >Удалить</button>
          <a
            v-if="!!order.pay_url"
            :href="order.pay_url"
            class="btn middle blue cards-item__btn"
          >Оплатить</a>
          <a
            v-if="order.report_url"
            v-tooltip="'Информация, содержащаяся в отчете, является<br>конфиденциальной и не подлежит разглашению.'"
            :href="order.report_url.replace(/(.*)(\/report.*)/gi, '$2')"
            class="btn middle green cards-item__btn"
            target="_blank"
            ref="reportLinks"
          >Посмотреть</a>
          <a
            v-if="order.download_url"
            v-tooltip="'Информация, содержащаяся в отчете, является<br>конфиденциальной и не подлежит разглашению.'"
            :href="order.download_url"
            class="btn middle green cards-item__btn"
            target="_blank"
            ref="reportLinks"
          >Скачать</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import ProductsList from '@/components/ProductsList'
import UserNav from '@/components/UserNav'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserOrders',
  namespace: true,

  components: {
    Loader,
    ProductsList,
    UserNav
  },

  data: () => ({
    isLoading: true,
    tooltipsInstanses: []
  }),

  computed: {
    ...mapGetters(['getOrders'])
  },

  methods: {
    ...mapActions(['fetchOrders', 'removeOrder'])
  },

  async mounted() {
    await this.fetchOrders()
    .finally(() => {
      this.isLoading = false
    })

    this.$refs.reportLinks?.map(el => this.tooltipsInstanses.push(
      M.Tooltip.init(el, {
        html: 'Информация, содержащаяся в отчете, является<br>конфиденциальной и не подлежит разглашению.',
        position: 'bottom'
      })
    ))
  },

  beforeDestroy() {
    this.tooltipsInstanses.map(i => i?.destroy());
  }
}
</script>

<style lang="scss">
.cards-item {
  background: #FEFEFE;
  border-radius: 18px;
  padding: 15px 70px;
  margin-bottom: 10px;

  @include sm {
    padding: 16px 16px 16px 50px;
  }
}

.cards-item__btns-wrap {
  display: flex;
  width: auto;
  justify-content: flex-start;

  @include sm {
    flex-direction: column;
    align-items: center;
  }
}

.cards-item__btn {
  display: block;
  margin: 5px;
  text-decoration: underline;
  background: unset;
  color: #343132;
  cursor: pointer;
  border: 0;
  transition: all .1s ease-in;

  &:hover {
    transform: translateX(-2px);
  }
}

.cards-item__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cards-item__row__status {
  position: absolute;
  left: -50px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  @include sm {
    min-width: 18px;
    left: -30px;
  }

  &.blue {
    background: #2F6BF0;
  }

  &.light-blue {
    background: #94E4F9;
  }
}

.cards-item__row__title {
  width: 95%;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 1.5;
}

.cards-item__row__title-item {
  padding-bottom: 10px;
}

.cards-item__row__title {
  @include sm {
    width: 100%;
    text-align: left;
    font-size: 14px;
  }
}

.cards-item__row__time,
.cards-item__row__date,
.cards-item__row__cost {
  font-size: 14px;
}

.cards-item__row__cost {
  font-weight: 600;
}
</style>
